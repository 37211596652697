import React, { useState, useEffect } from 'react';
import bancoLogo2 from '../assets/items/banco-logo2.png';
import './header.css';

const ScrollNavigation = () => {
  const [bancoChainTop, setBancoChainTop] = useState(0);
  const [bannerTop, setBannerTop] = useState(0);
  const [noticeTop, setNoticeTop] = useState(0);

  useEffect(() => {
    const bancoChainElement = document.querySelector('.main__banco-chain');
    const bannerElement = document.querySelector('.main__banner');
    const noticeElement = document.querySelector('.noticeImg');

    setBancoChainTop(bancoChainElement.offsetTop);
    setBannerTop(bannerElement.offsetTop);
    setNoticeTop(noticeElement.offsetTop);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const scrollToBancoChain = () => {
    window.scrollTo({ top: bancoChainTop, behavior: 'smooth' });
  };
  const scrollToBanner = () => {
    window.scrollTo({ top: bannerTop, behavior: 'smooth' });
  };

  const scrollToNotice = () => {
    window.scrollTo({ top:noticeTop, behavior: 'smooth' })
  }

  return { scrollToTop, scrollToBancoChain, scrollToBanner, scrollToNotice };
};

const Header = () => {
  const {
    scrollToTop,
    scrollToBancoChain,
    scrollToBanner,
    scrollToNotice,
  } = ScrollNavigation();

  return (
      <header className="App-header">
        <div className="navigation" >
          <div className="navigation__business">
            <span>
              <a onClick={scrollToBancoChain}>BUSINESS</a>
            </span>
          </div>
          <div className="navigation__logo" style={{marginLeft:'45px'}}>
            <a onClick={scrollToTop}>
              <img src={bancoLogo2} alt="header__logo" />
            </a>
          </div>
          <div className="navigation__banco" style={{marginLeft:'45px'}}>
            <span>
              <a onClick={scrollToBanner}>BANCO</a>
            </span>
          </div>
          <div className="navigation__banco" style={{marginLeft:'45px'}}>
            <span>
              <a onClick={scrollToNotice}>NOTICE</a>
            </span>
          </div>
        </div>
      </header>
  );
};

export default Header;
