import bancoLogoImg from '../../assets/items/banco-logo.png';
import exchangeImg from '../../assets/items/exchange_logo.png';
import './exchange.css';

const Exchange = () => {
  return (
    <div className="main__exchange">
    <div className="exchange__text-box">
      <div className="exchange__text-box-inside">
        <img
          className="up-on-scroll__x-left"
          src={bancoLogoImg}
          alt="main__logo"
        />
        <h3 className="up-on-scroll__x-left exchange__title">
          거래소
        </h3>
        <p className="up-on-scroll__x-left exchange__description">
          1. 투자피해 불공정 거래 없는 깨끗한 거래소
          <br />
          2. 엄격한 심사를 통해 잡코인 상장 無
          <br />
          3. 국내 최초 트래블룰 시스템 구축 및 특허 출원
          <br />
          4. 완벽한 자금세탁 방지 시스템과 내부 통제
          <br />
          5. 블록체인 산업발전 공로, 과학기술정통부 장관 표창 
          <br />
          6. 전문화된 코인거래 솔루션 "CoinDTS”
        </p>
      </div>
    </div>
    <div className="exchange__image-box">
      <img className="up-on-scroll__y" src={exchangeImg} alt="exchange" />
    </div>
  </div>
  );
};

export default Exchange;
