import bancoLogo2 from '../assets/items/banco-logo2.png';
import ismsFooterLogo from '../assets/items/isms_footer.png';
import iso27001FooterLogo from '../assets/items/iso-27001-logo.png';
import iso31000FooterLogo from '../assets/items/iso-31000-logo.png';
import './footer.css';
import OpenModalISMS from './Modal/OpenModalISMS';
import React, { useState } from 'react';

const Footer = () => {
  const [isOpen, setIsOpenISMS] = useState(false);
  const onClickISMS = () => {
    setIsOpenISMS(!isOpen);
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear()

  return (
    <>
      <footer className='App-footer up-on-scroll__y'>
        <div className='footer__logo'>
          <img src={bancoLogo2} alt='footer__logo'></img>
          <div className='footer__welcome'>Welcome to BANCO</div>
        </div>
        <div className='footer__info'>
          <p>
            (주)뱅코
            <br />
            본사 : 전라북도 전주시 덕진구 오공로 123, 8층 (806호, 807호)
            <br />
            연구소 : 서울특별시 강남구 언주로94길 15 3층
            <br />
            대표이사 : 김성훈
            <br />
            사업자등록번호 : 280-88-00977
            <br />
            Copyright ⓒ {year} BANCO. Inc. All Rights Reserved.
          </p>
        </div>
        
        <div className='footer__isms'>
          <img src={ismsFooterLogo} alt='isms인증' onClick={onClickISMS}></img>
          {/* <img style={{ width: '4rem'}} src={iso27001FooterLogo} alt='isms인증'></img>
          <img style={{ width: '4rem'}} src={iso31000FooterLogo} alt='isms인증'></img> */}
        </div>
       
      </footer>
      <OpenModalISMS open={setIsOpenISMS} isOpen={isOpen} />
    </>
  );
};

export default Footer;
