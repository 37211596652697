import React, { useEffect } from 'react';
import styled from 'styled-components';

function OpenModalISMS (props) {
  const handleClose = () => {
    props.open(false);
  };

  useEffect(() => {
    if (!props.isOpen) return false;

    const $body = document.querySelector('body');
    $body.style.overflow = 'hidden';
    return () => ($body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <Overlay isOpen={props.isOpen}>
      <ModalWrap>
        <Contents>
          <h3 style={{ textAlign: 'center' }}>정보보호 관리체계인증</h3>
          <h6 style={{ textAlign: 'left' }}>
            • 인증범위 : 가상자산 거래소 운영 (BORABIT)
            <br />
            • 유효기간 : 2024-03-16 ~ 2027-03-16
            <br />• 인증번호 : ISMS-KISA-2021-046
          </h6>
          <br />
          <div style={{ textAlign: 'center' }}>
            <Button onClick={handleClose}>확인</Button>
          </div>
        </Contents>
      </ModalWrap>
    </Overlay>
  );
}
const Overlay = styled.div`
  display: ${props => (!props.isOpen ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
  width: 100%;
  max-height: 300px;
  max-width: 400px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Contents = styled.div`
  font-size: 0.8rem;
  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.6rem;
    margin-bottom: 10px;
    background-color: #3F3F3F;
    color: white;
  }
  h6 {
    padding: .5rem 1.5rem;
    margin-top: .6rem;
  }
`;

const Button = styled.button`
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
`;

export default OpenModalISMS;
