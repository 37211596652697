import React, { useEffect } from 'react';
import styled from 'styled-components';

function OpenModal06 (props) {
  const handleClose = () => {
    props.open(false);
  };

  useEffect(() => {
    if (!props.isOpen) return false;

    const $body = document.querySelector('body');
    $body.style.overflow = 'hidden';
    return () => ($body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <Overlay isOpen={props.isOpen}>
      <ModalWrap>
        <Contents>
          <h2 style={{ textAlign: 'center' }}>임시주주총회 소집 공고</h2>
          <h6 style={{ textAlign: 'left' }}>
            주주 여러분의 건승과 댁내 평안을 기원합니다. 상법 제365조 및 당사
            정관 제22조에 의하여 임시주주총회를 다음과 같이 소집하오니 참석하여
            주시기 바랍니다.
          </h6>
          <br />
          <h5>- 아 래 -</h5>
          <br />
          <h6>1. 일 시 : 2023년 11월 23일 09:00</h6>
          <br />
          <h6>2. 장 소 : 서울시 강남구 언주로94길 15, 2층 (주)뱅코 회의실</h6>
          <br />
          <h6>3. 주주총회 안건</h6>
          <h6 className={'text-deep'}>
            ▷ <b>이사 선임의 건</b>
          </h6>
          <br />
          <h6>4. 주주의 의결권 행사에 관한 사항</h6>
          <h6 className={'text-deep'}>
            당사의 주주총회에는 당사 정관 제29조 규정에 따라서 대리인으로 하여금
            의결권을 행사할 수 있고, 서면에 의한 의결권행사는 당사의 소정 양식에
            의하여 행사하실 수 있습니다. 
          </h6>
          <br />
          <h6>5. 주주총회 참석 시 지참서류</h6>
          <h6 className={'text-deep'}>
            (1) 본인 참석 시 : 본인 신분증, 참석장
          </h6>
          <h6 className={'text-deep'}>
            (2) 대리인 참석 시 : 참석장, 수임인 신분증, 인감날인위임장,
            개인인감증명서 또는 법인인감증명서
          </h6>
          <br />
          <br />
          <h5>2023년 11월 06일</h5>
          <br />
          <h3 style={{ textAlign: 'center' }}>
            주식회사 뱅코
            <br />
            대표이사 김 성 훈
          </h3>
        </Contents>
        <div style={{ textAlign: 'center' }}>
          <Button onClick={handleClose}>닫기</Button>
        </div>
      </ModalWrap>
    </Overlay>
  );
}
const Overlay = styled.div`
  display: ${props => (!props.isOpen ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 98%;
  max-height: 98%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Contents = styled.div`
  margin: auto;
  padding: 32px 10px;
  aspect-ratio: 4 / 3;
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  h5 {
    // margin-left: 350px;
    text-align: center;
  }
  h6 {
    margin-left: 120px;
    margin-bottom: 10px;
    margin-right: 120px;
    &.text-deep {
      margin-left: 130px;
      margin-right: 130px;
    }
  }

  table {
    margin-left: 12.5%;
    margin-bottom: 30px;
  }

  table,
  th,
  td {
    text-align: center;
    border: 1px solid #bcbcbc;
  }

  th {
    width: 160px;
    text-decoration: none;
    color: black;
  }

  td {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 500px) {
    h5 {
      margin-left: 0;
    }
    h6 {
      &,
      &.text-deep {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    table {
      margin-left: 0;
    }
  }
`;

const Button = styled.button`
  font-size: 14px;
  padding: 10px 30px;
  margin-bottom: 20px;
  border: none;
  background-color: #ababab;
  border-radius: 10px;
  color: white;
  font-weight: 200;
  cursor: pointer;
  // float: right;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
    background-color: #898989;
  }
`;

export default OpenModal06;
