import React, { useEffect } from 'react';
import styled from 'styled-components';

function OpenModal01 (props) {
  const handleClose = () => {
    props.open(0);
  };

  useEffect(() => {
    if (!props.isOpen) return false;

    const $body = document.querySelector('body');
    $body.style.overflow = 'hidden';
    return () => ($body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <Overlay isOpen={props.isOpen}>
      <ModalWrap>
        <Contents>
          <h2 style={{ textAlign: 'center' }}>
            제3자 배정에 따른 신주 발행 공고
          </h2>
          <h6 style={{ textAlign: 'left' }}>
            주식회사 뱅코는 2022년 12월 20일 개최한 이사회에서 제3자
            배정방식으로
            <br />
            신주를 발행할 것을 결의한 바, 상법 418조 4항에 의거하여 아래와 같이
            <br />
            신주발행 내용을 공고합니다.
          </h6>
          <h2 style={{ textAlign: 'center' }}>- 아 래 -</h2>
          <h6>1. 신주의 종류와 수 : 기명식 보통주 32,262주</h6>
          <h6>2. 신주의 액면가액 : 1주당 금 오백원(￦500)</h6>
          <h6>3. 신주의 발행가액 : 1주당 금 만오천이백오십원(￦15,250)</h6>
          <h6>
            4. 신주의 발행가액 총액: 금
            사억구천백구십구만오천오백원(￦491,995,500)
          </h6>
          <h6>
            5. 주금납입 예정일 : 2023년 1월 5일 (단, 관계법령 및 투자자와의 협의
            하에 변경될 수 있음)
          </h6>
          <h6>
            6. 신주의 인수방법 : 상법 제 418조 제 2항 단서 및 회사 정관 제 9조
            제 2항
          </h6>
          <h6 className={'text-deep'}>
            제 4호에 의거 기존 주주의 신주인수권을 배제하고, 제3자에게 배정한다.
          </h6>
          <h6>7. 신주의 배정 내역</h6>
          <table>
            <thead>
              <tr>
                <th style={{ width: 'width: 100px' }}>배정대상자</th>
                <th style={{ width: 'width: 100px' }}>배정주식수</th>
                <th style={{ width: 'width: 100px' }}>배정금액(원)</th>
                <th style={{ width: 'width: 100px' }}>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>㈜넥스트테라투자일임</td>
                <td>32,262</td>
                <td>491,995,500</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <br />
          <h5>2022년 12월 20일</h5>
          <h5>주식회사 뱅코</h5>
          <h5>대표이사 윤상철</h5>
        </Contents>
        <div style={{ textAlign: 'center' }}>
          <Button onClick={handleClose}>닫기</Button>
        </div>
      </ModalWrap>
    </Overlay>
  );
}
const Overlay = styled.div`
  display: ${props => (!props.isOpen ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 98%;
  max-height: 98%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Contents = styled.div`
  margin: auto;
  padding: 32px 10px;
  aspect-ratio: 4 / 3;
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h5 {
    // margin-left: 350px;
    text-align: center;
  }
  h6 {
    margin-left: 100px;
    margin-bottom: 10px;
    padding-right: 10px;
    &.text-deep {
      margin-left: 123px;
    }
  }

  table {
    margin-left: 12.5%;
    margin-bottom: 30px;
  }

  table,
  th,
  td {
    text-align: center;
    border: 1px solid #bcbcbc;
  }

  th {
    width: 160px;
    text-decoration: none;
    color: black;
  }

  td {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 500px) {
    h5 {
      margin-left: 0;
    }
    h6 {
      &,
      &.text-deep {
        margin-left: 0px;
      }
    }
    table {
      margin-left: 0;
    }
  }
`;

const Button = styled.button`
  font-size: 14px;
  padding: 10px 30px;
  margin-bottom: 20px;
  border: none;
  background-color: #ababab;
  border-radius: 10px;
  color: white;
  font-weight: 200;
  cursor: pointer;
  // float: right;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
    background-color: #898989;
  }
`;

export default OpenModal01;
