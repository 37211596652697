import bancoLogoImg from '../../assets/items/banco-logo.png';
import coinDTSImg from '../../assets/items/coin_dts.png';
import './coindts.css';

const CoinDTS = () => {
  return (
    <div className="main__coindts">
    <div className="coindts__text-box">
      <div className="coindts__text-box-inside">
        <img
          className="up-on-scroll__x-left"
          src={bancoLogoImg}
          alt="main__logo"
        />
        <h3 className="up-on-scroll__x-left coindts__title">
          COIN DTS
          <br />
          국내외 여러 거래소를 동시에 
          <br/>
          이용할 수 있는 메타 트레이딩 솔루션
        </h3>
        <p className="up-on-scroll__x-left coindts__description">
          · 업비트, 빗썸, 코빗, 비트렉스, 바이낸스등 거래가능
          <br />
          · 각 거래소를 오가는 재정거래 (Arbitrage)
          <br />
          · 각 거래소별 순환거래 (Circular Trading)
          <br />
          · 기존 증권 HTS와 같은 친숙한 UI/UX
        </p>
      </div>
    </div>
    <div className="coindts__image-box">
      <img className="up-on-scroll__y" src={coinDTSImg} alt="coindts" />
    </div>
  </div>
  );
};

export default CoinDTS;
