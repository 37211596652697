import bancoLogoImg from '../../assets/items/banco-logo.png';
import cryptogardImg from '../../assets/items/cryptogard.png';
import './cryptogard.css';

const Cryptogard = () => {
  // if (navigator.userAgent.indexOf('Safari') !== -1) {
  //   console.log('fool safari');
  // }

  return (
    <div className="main__cryptogard">
      <div className="cryptogard__image-box">
        <img
          className="up-on-scroll__y-left"
          src={cryptogardImg}
          alt="cryptogard"
        />
      </div>
      <div className="cryptogard__empty-box" />
      <div className="cryptogard__text-box">
        <div className="cryptogard__text-box-inside">
          <img
            className="up-on-scroll__x-right"
            src={bancoLogoImg}
            alt="main__logo"
          />
          <h3 className="up-on-scroll__x-right cryptogard__title">크립토가드</h3>
          <p className="up-on-scroll__x-right cryptogard__description">
            국내 거래소의 문제점으로 부각되는 트래블룰 얼라이언스 형태의 폐쇄적 시스템을 보완하고
            <br />
            해외나 일반적인 월렛 서비스와 거래 연동을 가능하게 하는
            <br />
            “크립토 가드 트래블룰“ 시스템을 개발하였습니다.
            <br />
            현재 “크립토 가드” 는 특허 출원 중으로 보라비트는 국내에서
            <br />
            유일하게 해당 문제에 대응할 수 있는 거래소입니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cryptogard;
