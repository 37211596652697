import React, { useEffect } from 'react';
import styled from 'styled-components';

function OpenModal08 (props) {
  const handleClose = () => {
    props.open(false);
  };

  useEffect(() => {
    if (!props.isOpen) return false;

    const $body = document.querySelector('body');
    $body.style.overflow = 'hidden';
    return () => ($body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <Overlay isOpen={props.isOpen}>
      <ModalWrap>
        <Contents>
          <h2 style={{ textAlign: 'center' }}>정기주주총회 소집통지서</h2>
          <h6 style={{ textAlign: 'left' }}>
          주주님의 건승과 댁내 평안을 기원 합니다. (주)뱅코 제7기 정기주주총회를 아래와
          같이 개최하오니 참석하여 주시기를 바랍니다.

          </h6>
          <br />
          <h5>- 아&nbsp;&nbsp;&nbsp;&nbsp; 래 -</h5>
          <br />
          <h6><label style={{fontSize: '24px'}}>□</label> 일시 : 2024년 03월 26일(화요일) 10:00</h6>
          <h6><label style={{fontSize: '24px'}}>□</label> 장소 : 서울시 강남구 언주로94길 15, 3층 (주)뱅코 회의실</h6>
          <h6><label style={{fontSize: '24px'}}>□</label> 주주총회 안건</h6>
          <h6 className={'text-deep'}>
            <b>▷ 1. 제7기 재무제표 승인의 건</b><br />
            <b>▷ 2. 이사보수 한도액 승인의 건</b><br />
            <b>▷ 3. 감사보수 한도액 승인의 건</b>

          </h6>
          <h6><label style={{fontSize: '24px'}}>□</label> 주주총회 참석 시 지참서류</h6>
          <h6 className={'text-deep'}>
            ▷ 직접참석 : 본인 신분증, 주주총회 참석장<br />
            ▷ 대리참석 : 주주총회 참석장, 수임인 신분증(인감날인위임장, 주주인감증명서)
          </h6>
          <br />
          <h6>※ 당사에 위임장을 수여하실 경우 위임장에 내용을 기입 하시고, 개인성함 기재 및
            서명 날인 후 신분증사본을 첨부하여 아래 주소로 착불 등기로 송부해 주시기
            바랍니다. (법인의 경우 법인인감도장 날인 후 법인인감증명서 첨부)
          </h6>
          <br />
          <h6>※ 보내실 곳 : 서울시 강남구 언주로94길 15, 3층 주식회사 뱅코
          <h6 style={{ textAlign: 'center' }}>백 민 지 (Tel : 02-540-1257)</h6>

          </h6>
          <br />
          <br />
          <h5>2024년 03월 11일</h5>
          <br />
          <h3 style={{ textAlign: 'center' }}>
            주식회사 뱅코 대표이사&nbsp;&nbsp; 김 성 훈
          </h3>
        </Contents>
        <div style={{ textAlign: 'center' }}>
          <Button onClick={handleClose}>닫기</Button>
        </div>
      </ModalWrap>
    </Overlay>
  );
}
const Overlay = styled.div`
  display: ${props => (!props.isOpen ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 98%;
  max-height: 98%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Contents = styled.div`
  margin: auto;
  padding: 32px 10px;
  aspect-ratio: 4 / 3;
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  h5 {
    // margin-left: 350px;
    text-align: center;
  }
  h6 {
    margin-left: 120px;
    margin-bottom: 10px;
    margin-right: 120px;
    &.text-deep {
      margin-left: 130px;
      margin-right: 130px;
    }
  }

  table {
    margin-left: 12.5%;
    margin-bottom: 30px;
  }

  table,
  th,
  td {
    text-align: center;
    border: 1px solid #bcbcbc;
  }

  th {
    width: 160px;
    text-decoration: none;
    color: black;
  }

  td {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 500px) {
    h5 {
      margin-left: 0;
    }
    h6 {
      &,
      &.text-deep {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    table {
      margin-left: 0;
    }
  }
`;

const Button = styled.button`
  font-size: 14px;
  padding: 10px 30px;
  margin-bottom: 20px;
  border: none;
  background-color: #ababab;
  border-radius: 10px;
  color: white;
  font-weight: 200;
  cursor: pointer;
  // float: right;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
    background-color: #898989;
  }
`;

export default OpenModal08;
