import bancoLogoImg from '../../assets/items/banco-logo.png';
import iso31000Img from '../../assets/items/iso_31000_mark.png';
import './iso.css';

const Iso31000 = () => {
  // if (navigator.userAgent.indexOf('Safari') !== -1) {
  //   console.log('fool safari');
  // }

  return (
    <div className="main__iso">
      <div className="iso__image-box">
        <img
          className="up-on-scroll__y-left"
          src={iso31000Img}
          alt="iso_31000"
        />
      </div>
      <div className="iso__empty-box" />
      <div className="iso__text-box">
        <div className="iso__text-box-inside">
          <img
            className="up-on-scroll__x-right"
            src={bancoLogoImg}
            alt="main__logo"
          />
          <h3 className="up-on-scroll__x-right iso__title">ISO 31000</h3>
          <p className="up-on-scroll__x-right iso__description">
            ISO 31000은 모든 비즈니스 활동에서 발생하는 
            <br />
            모든 종류의 리스크를 관리하기 위한 가이드라인을 제공하는 국제 표준입니다.
            <br />
            이 표준은 원칙, 리스크 관리 프레임워크, 리스크 관리 프로세스
            <br />
            적용에 대한 가이드라인을 제공합니다. 
            <br/>
            <br/>
            <strong>ISO 31000:2018(E)</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Iso31000;
