import React, {useEffect} from "react";
import styled from "styled-components";


function OpenModal05(props) {

    const handleClose = () => {
        props.open(false);
    };

    useEffect(() => {
        if(!props.isOpen) return false;

        const $body = document.querySelector("body");
        $body.style.overflow = 'hidden';
        return () => ($body.style.overflow = "auto");
    }, [props.isOpen]);

    return (
        <Overlay isOpen={props.isOpen}>
            <ModalWrap>
                <Contents>
                    <br></br>
                    <br></br>
                    <h2 style={{textAlign:'center' ,fontSize:'40px'}}>임시주주총회 기준일 공고</h2>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h6 style={{textAlign:'center'}}>
                    상법 제354조 및 우리 회사 정관 제22조에 의거하여 2023년 10월 27일
                    <br></br>
                    <br></br>
                    현재 주주명부에 기재되어 있는 주주에게 의결권을 부여함을 공고합니다. 
                    </h6>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h5>2023년 10월 11일</h5>
                    <h5>주식회사 뱅코</h5>
                    <h5>대표이사 김성훈</h5>
                </Contents>
                <div style={{textAlign:'center'}}>
                    <Button onClick={handleClose}>닫기</Button>
                </div>
            </ModalWrap>
        </Overlay>
    );
}
const Overlay = styled.div`
  display: ${(props) =>
          !props.isOpen ? "none" : "block"};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
    width: 100%;
    height: 98%;
    max-height: 98%;
    max-width: 800px;
    border-radius: 10px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    
    @media only screen and (max-width: 500px) {
        width: 95%;
    }
`;

const Contents = styled.div`
    margin: auto;
    padding: 32px 10px;
    aspect-ratio: 4 / 3;
    h2 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    h5 {
        // margin-left: 350px;
        text-align: center;
    }
    h6 {
        margin-left: 20px;
        margin-bottom: 10px;
        padding-right: 10px;
        // &.text-deep {
        //     margin-left:123px
        // }
    }

    table {
        margin-left: 12.5%;
        margin-bottom: 30px;
    }

    table, th, td {
        text-align: center;
        border: 1px solid #bcbcbc;
    }

    th {
        width: 160px;
        text-decoration: none;
        color: black;
    }

    td {
        text-decoration: none;
        color: black;
    }
    
    @media only screen and (max-width: 500px) {
        h5 {
            margin-left: 0;
        }
        h6 {
            &, &.text-deep {
                margin-left: 0px;
            }
        }
        table {
            margin-left: 0;
        }
    }
`;

const Button = styled.button`
    font-size: 14px;
    padding: 10px 30px;
    margin-bottom: 20px;
    border: none;
    background-color: #ababab;
    border-radius: 10px;
    color: white;
    font-weight: 200;
    cursor: pointer;
    // float: right;
    margin-top: 5px;
    &:hover {
        cursor: pointer;
        background-color: #898989;
    }
`;

export default OpenModal05;
