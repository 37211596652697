//import bancoLogoImg from '../../assets/items/banco-logo.png';
import noticeImg from '../../assets/items/banco_logo_notice.png';
import './notice.css';
import OpenModal01 from '../Modal/OpenModal01';
import OpenModal02 from '../Modal/OpenModal02';
import OpenModal03 from '../Modal/OpenModal03';
import OpenModal04 from '../Modal/OpenModal04';
import OpenModal05 from '../Modal/OpenModal05';
import OpenModal06 from '../Modal/OpenModal06';
import OpenModal07 from '../Modal/OpenModal07';
import OpenModal08 from '../Modal/OpenModal08';
import React, { useState } from 'react';
import styled from 'styled-components';

const Notice = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);

  const onClickButton1 = () => {
    setIsOpen1(!isOpen1);
  };
  const onClickButton2 = () => {
    setIsOpen2(!isOpen2);
  };
  const onClickButton3 = () => {
    setIsOpen3(!isOpen3);
  };
  const onClickButton4 = () => {
    setIsOpen4(!isOpen4);
  };
  const onClickButton5 = () => {
    setIsOpen5(!isOpen5);
  };
  const onClickButton6 = () => {
    setIsOpen6(!isOpen6);
  };
  const onClickButton7 = () => {
    setIsOpen7(!isOpen7);
  };
  const onClickButton8 = () => {
    setIsOpen8(!isOpen8);
  };
  // if (navigator.userAgent.indexOf('Safari') !== -1) {
  //   console.log('fool safari');
  // }

  return (
    <div className='main__notice'>
      <div className='notice__image-box'>
        <img
          className='up-on-scroll__y-left noticeImg'
          src={noticeImg}
          alt='iso_27001'
        />
      </div>
      <div className='notice__empty-box' />
      <div className='notice__text-box'>
        <div className='notice__text-box-inside'>
          {/* <img
            className="up-on-scroll__x-right"
            src={bancoLogoImg}
            alt="main__logo"
          /> */}
          <h3 className='up-on-scroll__x-right notice__title'>공지사항</h3>
          <NoticeText className='notice_content' onClick={onClickButton8}>
            <div className='notice_subject'>
              <ListTitle>8. 정기주주총회 소집공고</ListTitle>
              <ListDate>2024.03.11</ListDate>
            </div>
            <OpenModal08 open={setIsOpen8} isOpen={isOpen8} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton7}>
            <div className='notice_subject'>
              <ListTitle>7. 이사회 소집공고</ListTitle>
              <ListDate>2024.03.04</ListDate>
            </div>
            <OpenModal07 open={setIsOpen7} isOpen={isOpen7} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton6}>
            <div className='notice_subject'>
              <ListTitle>6. 임시주주총회 소집공고</ListTitle>
              <ListDate>2023.11.06</ListDate>
            </div>
            <OpenModal06 open={setIsOpen6} isOpen={isOpen6} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton5}>
            <div className='notice_subject'>
              <ListTitle>5. 임시주주총회 기준일 공고</ListTitle>
              <ListDate>2023.10.11</ListDate>
            </div>
            <OpenModal05 open={setIsOpen5} isOpen={isOpen5} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton4}>
            <div className='notice_subject'>
              <ListTitle>4. 임시주주총회 소집 공고</ListTitle>
              <ListDate>2023.06.13</ListDate>
            </div>
            <OpenModal04 open={setIsOpen4} isOpen={isOpen4} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton3}>
            <div className='notice_subject'>
              <ListTitle>3. 임시주주총회 기준일 공고</ListTitle>
              <ListDate>2023.05.12</ListDate>
            </div>
            <OpenModal03 open={setIsOpen3} isOpen={isOpen3} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton2}>
            <div className='notice_subject'>
              <ListTitle>2. 정기주주총회 소집 공고</ListTitle>
              <ListDate>2023.03.14</ListDate>
            </div>
            <OpenModal02 open={setIsOpen2} isOpen={isOpen2} />
          </NoticeText>

          <NoticeText className='notice_content' onClick={onClickButton1}>
            <div className='notice_subject'>
              <ListTitle>1. 제3자 배정에 따른 신주 발행 공고</ListTitle>
              <ListDate>2022.12.20</ListDate>
            </div>
            <OpenModal01 open={setIsOpen1} isOpen={isOpen1} />
          </NoticeText>
        </div>
      </div>
    </div>
  );
};

const ListTitle = styled.div`
  color: black;
  font-size: 20px;
  width: 80%;
  cursor: pointer;
  @media only screen and (min-width: 320px) and (max-width: 580px) and (orientation: portrait) {
    font-size: 15px;
    width: 80%;
  }
`;
const ListDate = styled.div`
  font-size: 20px;
  width: 20%;
  text-align: right;
  @media only screen and (min-width: 320px) and (max-width: 580px) and (orientation: portrait) {
    font-size: 15px;
    width: 20%;
  }
`;
const NoticeText = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 580px) and (orientation: portrait) {
    display: flex;
  }
`;
export default Notice;
