import React, { useEffect } from 'react';
import styled from 'styled-components';

function OpenModal07 (props) {
  const handleClose = () => {
    props.open(false);
  };

  useEffect(() => {
    if (!props.isOpen) return false;

    const $body = document.querySelector('body');
    $body.style.overflow = 'hidden';
    return () => ($body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <Overlay isOpen={props.isOpen}>
      <ModalWrap>
        <Contents>
          <h2 style={{ textAlign: 'center' }}>이사회 소집 통지서</h2>
          <h6 style={{ textAlign: 'left' }}>
            당 회사 정관 제39조에 의거, 다음과 같이 이사회 소집을 통지 합니다.
          </h6>
          <br />
          <h5>▶ 다 음 ◀</h5>
          <br />
          <h6>1.&nbsp; 일 시&nbsp; : &nbsp;2024년 3월 8일 금요일 오전 10시</h6>
          <br />
          <h6>2.&nbsp; 장 소&nbsp; : &nbsp;서울시 강남구 언주로94길 15, 3층 회의실</h6>
          <br />
          <h6>3.&nbsp; 주요 의안</h6>
          <h6 className={'text-deep'}>
          <b>&nbsp;(1)&nbsp; 2024년 정기총회 관련 건</b>
          </h6>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h5>2024년 03월 04일</h5>
          <br />
          <h3 style={{ textAlign: 'center' }}>
            주식회사 뱅 코
            <br />
            대 표 이 사&nbsp;&nbsp; 김 성 훈&nbsp;&nbsp;  (인)
          </h3>
          <br />
          <br />
          <br />
          <br />
          <h3 style={{ textAlign: 'center' }}>
            주식회사 뱅코 이사/감사님 귀하
          </h3>

        </Contents>
        <div style={{ textAlign: 'center' }}>
          <Button onClick={handleClose}>닫기</Button>
        </div>
      </ModalWrap>
    </Overlay>
  );
}
const Overlay = styled.div`
  display: ${props => (!props.isOpen ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 98%;
  max-height: 98%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Contents = styled.div`
  margin: auto;
  padding: 32px 10px;
  aspect-ratio: 4 / 3;
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  h5 {
    // margin-left: 350px;
    text-align: center;
  }
  h6 {
    margin-left: 120px;
    margin-bottom: 10px;
    margin-right: 120px;
    &.text-deep {
      margin-left: 130px;
      margin-right: 130px;
    }
  }

  table {
    margin-left: 12.5%;
    margin-bottom: 30px;
  }

  table,
  th,
  td {
    text-align: center;
    border: 1px solid #bcbcbc;
  }

  th {
    width: 160px;
    text-decoration: none;
    color: black;
  }

  td {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 500px) {
    h5 {
      margin-left: 0;
    }
    h6 {
      &,
      &.text-deep {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    table {
      margin-left: 0;
    }
  }
`;

const Button = styled.button`
  font-size: 14px;
  padding: 10px 30px;
  margin-bottom: 20px;
  border: none;
  background-color: #ababab;
  border-radius: 10px;
  color: white;
  font-weight: 200;
  cursor: pointer;
  // float: right;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
    background-color: #898989;
  }
`;

export default OpenModal07;
