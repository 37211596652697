import React, { useEffect } from 'react';
import styled from 'styled-components';

function OpenModal02 (props) {
  const handleClose = () => {
    props.open(0);
  };

  useEffect(() => {
    if (!props.isOpen) return false;

    const $body = document.querySelector('body');
    $body.style.overflow = 'hidden';
    return () => ($body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <Overlay isOpen={props.isOpen}>
      <ModalWrap>
        <Contents>
          <h2 style={{ textAlign: 'center' }}>정기주주총회 소집공고</h2>
          <h6 style={{ textAlign: 'left' }}>
            주주 여러분의 건강과 행복을 기원합니다. 당사는 상법 제365조 및 정관
            제22조에 의거, 다음과 같이 제6기 정기주주총회를 소집하 오니,
            참석하시는 주주 여러분께서는 참석장, 신분증 등을 지참하여 주시기
            바랍니다.
          </h6>
          <br />
          <h2 style={{ textAlign: 'center' }}>- 아 래 -</h2>
          <h6>1. 일 시 : 2023년 3월 29일(수요일) 오전 9 시</h6>
          <h6>2. 장 소 : 서울시 강남구 언주로94길 15, 2층 (주)뱅코 회의실</h6>
          <h6>3. 부의 안건</h6>
          <h6 className={'text-deep'}>제1호 의안 제6기 재무제표 승인의 건</h6>
          <h6 className={'text-deep'}>제2호 의안 이사보수 한도액 승인의 건</h6>
          <h6 className={'text-deep'}>제3호 의안 감사보수 한도액 승인의 건</h6>
          <h6>4. 주주총회 참석 시 지참서류</h6>
          <h6 className={'text-deep'}>
            ▷ 직접참석 : 본인 신분증, 주주총회 참석장
          </h6>
          <h6 className={'text-deep'}>
            ▷ 대리참석 : 주주총회 참석장, 수임인 신분증(인감날인위임장,
            주주인감증명서)
          </h6>
          <br />
          <br />
          <br />
          <h5>2023년 3월 14일</h5>
          <h5>주식회사 뱅코</h5>
          <h5>대표이사 윤상철</h5>
        </Contents>
        <div style={{ textAlign: 'center' }}>
          <Button onClick={handleClose}>닫기</Button>
        </div>
      </ModalWrap>
    </Overlay>
  );
}
const Overlay = styled.div`
  display: ${props => (!props.isOpen ? 'none' : 'block')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: auto;
`;

const ModalWrap = styled.div`
  width: 100%;
  height: 98%;
  max-height: 98%;
  max-width: 800px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

const Contents = styled.div`
  margin: auto;
  padding: 32px 10px;
  aspect-ratio: 4 / 3;
  h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h5 {
    // margin-left: 350px;
    text-align: center;
  }
  h6 {
    margin-left: 100px;
    margin-bottom: 10px;
    padding-right: 10px;
    &.text-deep {
      margin-left: 123px;
    }
  }

  table {
    margin-left: 12.5%;
    margin-bottom: 30px;
  }

  table,
  th,
  td {
    text-align: center;
    border: 1px solid #bcbcbc;
  }

  th {
    width: 160px;
    text-decoration: none;
    color: black;
  }

  td {
    text-decoration: none;
    color: black;
  }

  @media only screen and (max-width: 500px) {
    h5 {
      margin-left: 0;
    }
    h6 {
      &,
      &.text-deep {
        margin-left: 0px;
      }
    }
    table {
      margin-left: 0;
    }
  }
`;

const Button = styled.button`
  font-size: 14px;
  padding: 10px 30px;
  margin-bottom: 20px;
  border: none;
  background-color: #ababab;
  border-radius: 10px;
  color: white;
  font-weight: 200;
  cursor: pointer;
  // float: right;
  margin-top: 5px;
  &:hover {
    cursor: pointer;
    background-color: #898989;
  }
`;

export default OpenModal02;
