import bancoLogoImg from '../../assets/items/banco-logo.png';
import isoImg from '../../assets/items/iso_27001.png';
import './iso.css';

const Iso = () => {
  // if (navigator.userAgent.indexOf('Safari') !== -1) {
  //   console.log('fool safari');
  // }

  return (
    <div className="main__iso">
      <div className="iso__image-box">
        <img
          className="up-on-scroll__y-left"
          src={isoImg}
          alt="iso_27001"
        />
      </div>
      <div className="iso__empty-box" />
      <div className="iso__text-box">
        <div className="iso__text-box-inside">
          <img
            className="up-on-scroll__x-right"
            src={bancoLogoImg}
            alt="main__logo"
          />
          <h3 className="up-on-scroll__x-right iso__title">ISO 27001</h3>
          <p className="up-on-scroll__x-right iso__description">
            ISO 27001는 정보보안경영시스템(ISMS)에 관한 가장 권위있는 국제 표준 인증으로
            <br />
            정보보호정책 · 물리적 보안 · 정보접근통제 등 정보보안 관련 14개 영역,
            <br />
            114개 관리 기준에 대해 엄격한 심사를 통과하였습니다.
            <br />
            거래소 서비스를 운영하며 단 한 건의 해킹 사례가 발생하지 않았을 정도로 안전한 환경을 제공합니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Iso;
