import bancoLogoImg from '../../assets/items/banco-logo.png';
import ismsImg from '../../assets/items/isms.png';
import './isms.css';

const Isms = () => {
  return (
    <div className="main__isms">
    <div className="isms__text-box">
      <div className="isms__text-box-inside">
        <img
          className="up-on-scroll__x-left"
          src={bancoLogoImg}
          alt="main__logo"
        />
        <h3 className="up-on-scroll__x-left isms__title">
          ISMS
        </h3>
        <p className="up-on-scroll__x-left isms__description">
          거래소가 획득한 ISMS인증은 정보보호를 위한 
          <br />
          일련의 기술적 조치와 정보보호체계가 기준에 적합한지 
          <br />
          한국인터넷진흥원(KISA)이 증명하는 제도입니다.<br />
        </p>
      </div>
    </div>
    <div className="isms__image-box">
      <img className="up-on-scroll__y" src={ismsImg} alt="isms" />
    </div>
  </div>
  );
};


export default Isms;
