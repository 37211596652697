import Header from './components/Header';
import InitCarousel from './components/Main/Carousel';
import Main from './components/Main';
import Footer from './components/Footer';
import './App.css';
// import { Button } from 'antd';
// import { CloseCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.min.css';

// 방법1
// export const App = () => {
const App = () => {
  // const closePopup = () => {
  //   const popup = document.querySelector('.popup');
  //   popup.style.display = 'none';

  //   const body = document.querySelector('body');
  //   body.style.overflowY = 'visible';
  // };

  return (
    <>
      {/*
      <div className="popup">
        <div className="notice">
          <CloseCircleOutlined className="close" onClick={closePopup} />
          <h3 className="title">주식액면분할 및 주권제출공고</h3>
          <p className="contents">
            &nbsp;당사는 2021년 4월 23일 임시주주총회의 결의로 1주의 금액
            5,000원 주식 1주를 분할하여 1주의 금액 500원의 주식 10주로
            분할하기로 하였으므로 구 주권을 가진 주주께서는 이 공고 게재일로부터
            1월 내에 구 주권을 본사에 제출하여 주시기 바랍니다.
          </p>
          <div className="sub date">2021.05.14.</div>
          <div className="sub notice_date">
            (공고일: 2 021년 5월 14일부터 1개월간, www.banco.id)
          </div>
          <div className="sub banco">
            <p>주식회사 뱅코</p>
            <p>전라북도 전주시 만성북로 21-26, 4층 1호</p>
            <p>대표이사 민경임 (직인생략)</p>
          </div>
        </div>
      </div>
      */}

      <div className="App">
        <Header />
        <InitCarousel />
        <Main />
        <Footer />
      </div>
    </>
  );
};

// 방법2
export default App;
